<template>
  <WsFlex
    flexDirection="column"
    alignItems="center"
  >
    <WsContent
      class="w-full"
      maxWidth="600px"
    >
      <WsMain>
        <WsFlex
          flexDirection="column"
          alignItems="center"
        >
          <WsAvatar
            size="120"
            :src="_currentUser.avatar"
          ></WsAvatar>
          <WsText
            size="18"
            class="center mt-24"
          >{{_currentUser.name}}</WsText>
          <WsInfo
            type="multi-select"
            :items="_scopeItems"
            :value="$store.state.auth.scopes"
          ></WsInfo>
          <AuthBindBtns v-if="_showAuthBindBtns" />
          <WsBtn
            class="mt-24"
            minWidth="200"
            to="/me/update"
          >{{$t('編輯資料')}}</WsBtn>
          <WsBtn
            class="mt-24"
            minWidth="200"
            to="/me/passwordupdate"
          >{{$t('修改密碼')}}</WsBtn>
          <WsBtn
            class="mt-24"
            minWidth="200"
            outlined
            to="/logout"
          >{{$t('登出')}}</WsBtn>
        </WsFlex>
      </WsMain>
    </WsContent>
  </WsFlex>
</template>

<script>
export default {
  computed: {
    _currentUser() {
      return this.$store.state.auth.currentUser;
    },
    _scopeItems() {
      return this.$o_o.$h.scope.getFilteredAdminScopes();
    },
    _showAuthBindBtns() {
      return this.$o_o.$c.wsmodule?.socialite_cms
    }
  },
};
</script>

<style>
</style>